import { Formik, Form, FieldArray } from 'formik';
import {
  Button, Box, Tooltip, Grid, Stack, Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { TextFieldWithError } from '../../../../components/FormComponents';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { toolTipText } from '../../../../utils/toolTipText';
import RuleComponent from '../RuleComponent/RuleComponent';
import ScoreCriteria from './ScoreCriteria';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';

function RuleForm({
  scoreControlsEnabled,
  initialValues,
  validationSchema,
  onSubmit,
  handleRuleChange,
  data,
  featureManagerListData,
  isEditPage,
  initialRuleState,
  isCopyClick,
  healthScoreCriteria,
  setHealthScoreCriteria,
  primaryHealthScore,
  handleAddCriteriaRow,
  activePackage,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles(styles)();

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        values, touched, errors, handleChange, handleBlur, setFieldValue, isValid,
      }) => (
        <Form>
          {/* Label */}
          <Grid spacing={2} className={classes.cardStyles}>
            <Typography variant="h6">KPI Score Label</Typography>
            <TextFieldWithError
              name="healthScoreLabel"
              placeholder="KPI Score Label"
              value={values?.healthScoreLabel}
              onChange={handleChange}
              focused={isCopyClick}
              autoFocus={isCopyClick}
              errorDisabled
            />
          </Grid>

          {/* Parameters */}
          <Grid spacing={2} className={classes.cardStyles} mt={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h6">Parameters</Typography>
                <Tooltip title={toolTipText.healthInsights.PARAMETERS}>
                  <InfoIcon color="primary" fontSize="small" />
                </Tooltip>
              </Box>
              <Stack direction="row" alignItems="center" gap={2}>
                {primaryHealthScore && (
                <Typography
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: 'success.lighter',
                    padding: '2px 8px',
                    color: 'success.dark',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  Primary KPI Score
                </Typography>
                )}
                <Tooltip title={primaryHealthScore && toolTipText.healthInsights.NO_PARAMETERS}>
                  <span>
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={primaryHealthScore}
                      onClick={handleAddCriteriaRow}
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </Stack>

            {(!primaryHealthScore && healthScoreCriteria?.length > 0) && <ScoreCriteria activePackage={activePackage} healthScoreCriteria={healthScoreCriteria} setHealthScoreCriteria={setHealthScoreCriteria} />}
          </Grid>

          {/* Rules */}
          <FieldArray name="healthScoreRules">
            {({ push, remove, insert }) => (
              <Stack gap={2} mt={2}>
                <Grid spacing={2} className={classes.cardStyles}>
                  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="h6">Rules</Typography>
                      <Tooltip title={toolTipText.healthInsights.RULES}>
                        <InfoIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Box>

                    <Tooltip title={values.healthScoreRules?.length >= 10 && toolTipText.healthInsights.ADD_RULE_FAIL}>
                      <span>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => (values?.healthScoreRules?.length < 10 ? push(initialRuleState.healthScoreRules[0]) : dispatch(openSnackbar({ open: true, message: toolTipText.healthInsights.ADD_RULE_FAIL, type: 'error' })))}
                        >
                          Add
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                </Grid>

                {values.healthScoreRules?.map((ruleData, index) => (
                  <Grid spacing={2} className={classes.cardStyles}>
                    <RuleComponent
                      key={index}
                      index={index}
                      ruleData={ruleData}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleRemove={remove}
                      handleRuleChange={handleRuleChange}
                      setFieldValue={setFieldValue}
                      featureManagerListData={featureManagerListData}
                      isEditPage={isEditPage}
                      isValid={isValid}
                      insert={insert}
                    />
                  </Grid>
                ))}
              </Stack>
            )}
          </FieldArray>

          {/* Action */}
          <Grid item xs={12} mt={4}>
            <Stack spacing={1} direction="row" alignItems="center" justifyContent="end">
              <Button variant="outlined" size="small" onClick={() => navigate('/kpi-score-configuration')}>
                Cancel
              </Button>

              <Tooltip title={data?.healthScoreLabel?.length === 0 && toolTipText.healthInsights.HEALTH_SCORE_LABEL}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={!scoreControlsEnabled || !isValid}
                >
                  Save
                </Button>
              </Tooltip>
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default RuleForm;
