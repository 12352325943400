import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { getDate } from '../../../../utils/getDate';

const currentDate = getDate();

const criteriaOptions = [
  { id: 'accounts', name: 'Accounts', value: 'accounts' },
  { id: 'seats', name: 'Seats Assignment', value: 'seats' },
  { id: 'baselineUsers', name: 'Baseline Users', value: 'baselineUsers' },
];

const operatorOptions = [
  { id: 'greaterThan', name: 'Greater than (>)', value: '>' },
  { id: 'greaterThanEquals', name: 'Greater than, Equals to (> =)', value: '>=' },
  { id: 'lessThan', name: 'Less Than (<)', value: '<' },
  { id: 'lessThanEquals', name: 'Less than, Equals to (< =)', value: '<=' },
  { id: 'equalsTo', name: 'Equals to (=)', value: '==' },
];

function useScoreCriteria({ setHealthScoreCriteria }) {
  const accountListData = useSelector((state) => state.accountsList);
  const [accountList, setAccountList] = useState([]);

  const setAccountListData = () => {
    const { accountsList } = accountListData;
    const filterAccount = [];
    const orgIdAccount = [];
    if (accountsList?.length) {
      accountsList.forEach((acc) => {
        const isActive = acc.status === 'Active';
        const isNeverExpire = acc.renewalDate === null;
        const isRenewalDateValid = acc.renewalDate && getDate(acc.renewalDate) >= currentDate.setHours(0, 0, 0, 0);

        if (acc.visibility && (isActive && (isRenewalDateValid || isNeverExpire))) {
          const object = {};
          object.value = acc.orgId;
          object.name = acc.accountName || acc.orgId;
          object.id = acc.orgId;
          if (!acc.accountName) {
            orgIdAccount.push(object);
          } else {
            filterAccount.push(object);
          }
        }
      });
    }
    const sortedData = orderBy(filterAccount, ['name'], ['asc']);
    setAccountList([...sortedData, ...orgIdAccount]);
  };

  useEffect(() => {
    setAccountListData();
  }, [accountListData.accountsList]);

  const handleSelectCriteria = useCallback((e, index) => {
    const { name, value } = e.target;
    setHealthScoreCriteria((prevRows) => {
      const newRows = [...prevRows];
      if (value === 'accounts') {
        newRows[index] = {
          criteria: value,
          operator: '',
          value: '',
          andOr: '&&',
          accounts: [],
        };
      } else {
        newRows[index] = { ...newRows[index], [name]: value };
      }
      return newRows;
    });
  }, []);

  const handleSelectAccounts = useCallback((e, index) => {
    setHealthScoreCriteria((prevRows) => {
      const newRows = [...prevRows]; // Shallow copy of the array
      const updatedRow = { ...newRows[index] }; // Shallow copy of the specific row
      updatedRow.accounts = e.target.value; // Modify the deep copied row
      newRows[index] = updatedRow; // Replace the old row with the updated one
      return newRows;
    });
  }, []);

  const handleRemoveCriteriaRow = useCallback((index) => {
    setHealthScoreCriteria((prevRows) => prevRows.filter((_, i) => i !== index));
  }, []);

  const handleChangeCondition = useCallback((index, value) => {
    setHealthScoreCriteria((prevRows) => {
      const newRows = [...prevRows]; // Shallow copy of the array
      const updatedRow = { ...newRows[index] }; // Shallow copy of the specific row
      updatedRow.andOr = value; // Modify the deep copied row
      newRows[index] = updatedRow; // Replace the old row with the updated one
      return newRows;
    });
  }, []);

  const buildLogicalExpression = (conditions) => {
    const conditionSet = {
      seats: 'Seats Assignment',
      accounts: 'Selected Accounts',
      baselineUsers: 'Baseline Users',
    };

    if (!conditions.length) return '';

    // Helper function to get the expression for each condition
    const getExpression = (condition) => {
      const {
        criteria, operator, value, accounts,
      } = condition;

      if (!criteria) return '';
      if (criteria === 'accounts') {
        return `${accounts?.length ?? 0} Selected Accounts`;
      }

      const suffix = criteria === 'seats' ? '%' : '';
      return `${conditionSet[criteria] ?? ''} ${operator} ${value}${suffix}`;
    };

    // Initialize the expression with the first condition
    let expression = getExpression(conditions[0]);

    // Iterate through the conditions, starting from the second one
    for (let i = 1; i < conditions.length; i++) {
      const current = conditions[i];
      const andOr = conditions[i - 1].andOr || '&&';
      expression = `(${expression} ${andOr} ${getExpression(current)})`;
    }

    return expression;
  };

  return {
    accountListData,
    accountList,
    criteriaOptions,
    operatorOptions,
    handleSelectCriteria,
    handleSelectAccounts,
    handleRemoveCriteriaRow,
    handleChangeCondition,
    buildLogicalExpression,
  };
}

export default useScoreCriteria;
