// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { Broadcast } from './components/Broadcast';
import { SnackBar } from './components/SnackBar';
import { SessionProvider } from './components/SessionProvider';
import Socket from './components/Socket/Socket';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <SessionProvider />
      <Broadcast />
      <SnackBar />
      <Socket />
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
